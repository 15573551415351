.app {
  text-align: center;
  padding-top: rem(80px);
  background-color: $white;
  color: $black;
  font-family: Montserrat;
  a {
    color: $black;
    transition: color 0.2s;
    &:hover {
      color: $grey;
    }
  }
  &-maintenance {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(#{rem(10px)} + 2vmin);
    color: grey;
  }
}
