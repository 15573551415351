.page {
  position: absolute;
  height: calc(100% - #{rem(80px)});
  width: 100%;
  top: rem(80px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a {
    text-decoration: none;
  }
}
