.menu {
  $scope: &;

  position: relative;
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  min-width: 60%;
  padding-right: rem(32px);
  height: rem(64px);
  transition: height 0.5s;

  ul {
    display: flex;
    list-style-type: none;
    margin-top: rem(24px);
    li {
      height: fit-content;
      padding: 0 rem(16px) rem(16px);
      a {
        text-decoration: none;
      }
    }
  }

  #{$scope}__album {
    ul {
      position: absolute;
      text-align: left;
      right: rem(16px);
      top: rem(80px);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.1s;
      flex-wrap: wrap;
    }
    > a {
      display: flex;
      width: fit-content;
      &:after {
        content: "";
        display: inline-block;
        border: solid;
        border-width: rem(1px) rem(1px) 0 0;
        height: rem(4px);
        width: rem(4px);
        left: rem(8px);
        position: relative;
        top: rem(8px);
        transform: rotate(135deg);
        transition: transform 0.5s;
      }
    }
    &--show {
      ul {
        display: flex;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s 0.1s;
      }
      > a {
        color: $grey;
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__link {
    &:last-child {
      padding-right: 0;
    }
  }

  &--extended {
    height: 50vh;
  }
}

@media (max-width: $sm) {
  .menu {
    $scope: &;

    width: 100%;
    height: 0;

    ul {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.1s;
      flex-direction: column;
      padding: rem(64px) 0;
      text-align: left;
      margin: 0 auto;
      max-width: rem(100px);
      li {
        padding: rem(8px) rem(16px);
      }
    }

    #{$scope}__album--show {
      ul {
        right: unset;
        padding: rem(8px) 0;
        position: relative;
        top: 0;
      }
    }

    &--open {
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      ul {
        visibility: visible;
        opacity: 1;
        transition: opacity 1s;
      }
    }
  }
}
