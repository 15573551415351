.image {
  padding: 0 rem(32px);
  padding-bottom: rem(32px);
  &:last-child {
    padding-bottom: rem(80px);
  }
  img {
    max-width: 100%;
    max-height: 90vh;
  }
  &__text {
    position: relative;
    height: calc(100vh - #{rem(160px)});
    a {
      position: relative;
      display: flex;
      height: 100%;
      img {
        width: calc(100vw - #{rem(64px)});
        object-fit: cover;
      }
      h1 {
        color: $white;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    z-index: 2;
    cursor: pointer;
  }
  &--zoomed {
    img {
      width: unset;
      max-height: 90vh;
      max-width: 100vw;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 3;
    }
  }
  &--disabled {
    cursor: unset;
  }
}

@media (max-width: $xs) {
  .image {
    padding: 0 rem(32px);
    &--zoomed {
      img {
        max-height: 100vh;
      }
    }
  }
}
