.header {
  position: fixed;
  width: 100%;
  background: $white;
  display: flex;
  top: 0;
  z-index: 1;
  transition: top 0.5s;
  &__logo {
    display: flex;
    align-items: center;
    min-height: rem(64px);
    max-height: rem(64px);
    padding-left: rem(32px);
    width: inherit;
    text-align: left;
  }
  &--hidden {
    top: rem(-64px);
  }
  &--extended {
    border-bottom: rem(1px) solid $lightgrey;
  }
}

@media (max-width: $sm) {
  .header {
    display: block;
    &__logo {
      width: 100%;
    }
    &--lock {
      top: 0;
    }
  }
}
