// Utils

@import "styles/utils/rem";

// Variables

@import "styles/variables/breakpoints";
@import "styles/variables/colors";

// Fonts

@import url(https://fonts.googleapis.com/css?family=Montserrat);

// Main

@import "App";

// Components

@import "components/Burguer";
@import "components/Image";
@import "components/Page";

// Containers

@import "containers/Album";
@import "containers/Header";
@import "containers/Menu";

// Default

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
