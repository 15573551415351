.burguer {
  $scope: &;

  display: none;
  height: rem(64px);
  width: rem(32px);
  position: absolute;
  right: rem(32px);
  top: 0;
  font-size: rem(12px);
  cursor: pointer;
  transition: all 0.2s;

  &__lines {
    top: 50%;
    &,
    &:after,
    &:before {
      pointer-events: none;
      display: block;
      content: "";
      width: 100%;
      border-radius: rem(4px);
      background-color: $black;
      height: rem(4px);
      position: absolute;
      transform: rotate(0);
    }
  }

  &__arrow {
    #{$scope}__lines {
      &:before {
        left: rem(16px);
        top: rem(16px);
      }
      &:after {
        left: 0;
        top: rem(-16px);
      }
      &,
      &:after,
      &:before {
        transition: top 0.2s, left 0.2s, background-color 0.4s 0.2s;
      }
      &:after,
      &:before {
        width: rem(16px);
      }
    }
    
    &#{$scope}--open {
      #{$scope}__lines {
        background-color: hsla(0, 0, 0, 0.7);
        &:before {
          top: rem(-4px);
          transform: rotate(-45deg);
        }
        &:after {
          top: rem(4px);
          transform: rotate(45deg);
        }
        &:after,
        &:before {
          left: rem(-4px);
        }
        &,
        &:after,
        &:before {
          transition: background-color 0.2s, top 0.2s, left 0.2s;
        }
      }
      &#{$scope}--up {
        transform: rotate(90deg);
      }
    }
  }
}

@media (max-width: $sm) {
  .burguer {
    display: block;
  }
}
